import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { colors, easings, fonts, grid } from 'src/styles';

class CaseModalComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { caseIndex, cases } = this.props;
        return caseIndex !== null ? (
            <>
                <Global styles={modalCss} />
                <Modal
                    open={this.props.open}
                    onClose={this.props.closeModal}
                    blockScroll={true}
                    showCloseIcon={false}
                    animationDuration={600}
                    classNames={{
                        overlay: 'overlay',
                        modal: 'modal',
                        transitionEnter: 'transitionEnter',
                        transitionEnterActive: 'transitionEnterActive',
                        transitionExit: 'transitionExitActive',
                        transitionExitActive: 'transitionExitActive',
                    }}>
                    <ModalContainer>
                        <ModalNavigation>
                            {this.props.showPrevious ? (
                                <span onClick={this.props.previousCase}>
                                    {'<<'}
                                </span>
                            ) : (
                                <span className="fake" />
                            )}
                            <span onClick={this.props.closeModal}>X</span>

                            {this.props.showNext ? (
                                <span onClick={this.props.nextCase}>
                                    {'>>'}
                                </span>
                            ) : (
                                <span className="fake" />
                            )}
                        </ModalNavigation>
                        <TransitionGroup>
                            {cases.map(caseNode => {
                                const c = caseNode.node;
                                if (c.position === caseIndex + 1) {
                                    return (
                                        <CSSTransition
                                            key={c.position}
                                            className="case-transition"
                                            classNames="case-transition"
                                            timeout={{
                                                enter: 500,
                                                exit: 300,
                                            }}>
                                            <ModalContent>
                                                <CaseImageWrapper>
                                                    <div>
                                                        <Img
                                                            fadeIn={true}
                                                            fluid={
                                                                c.clientLogo
                                                                    .fluid
                                                            }
                                                        />
                                                    </div>
                                                </CaseImageWrapper>
                                                <h5>
                                                    {c.link ? (
                                                        <a
                                                            href={c.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {c.title}
                                                        </a>
                                                    ) : (
                                                        <span>{c.title}</span>
                                                    )}
                                                </h5>
                                                <h4>{c.client}</h4>
                                                {c.collaboration && (
                                                    <CaseCollaboration>
                                                        <a
                                                            href={
                                                                c.collaborationLink
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            I samarbejde med{' '}
                                                            {c.collaboration}
                                                        </a>
                                                    </CaseCollaboration>
                                                )}
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: c.description,
                                                    }}
                                                />
                                                {c.awards.length > 0 && (
                                                    <Awards>
                                                        <h5>
                                                            Awards &amp;
                                                            mentions
                                                        </h5>
                                                        {c.awards.map(
                                                            (award, index) => (
                                                                <p key={index}>
                                                                    {award.link ? (
                                                                        <a
                                                                            href={
                                                                                award.link
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">
                                                                            {
                                                                                award.name
                                                                            }
                                                                        </a>
                                                                    ) : (
                                                                        <span>
                                                                            {
                                                                                award.name
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </p>
                                                            )
                                                        )}
                                                    </Awards>
                                                )}
                                            </ModalContent>
                                        </CSSTransition>
                                    );
                                }
                            })}
                        </TransitionGroup>
                    </ModalContainer>
                </Modal>
            </>
        ) : null;
    }
}

const ModalContainer = styled.div`
    ${grid.contentWidth};

    ${grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${grid.gutter('padding-top', {
        0: 6,
        20: 6,
        30: 8,
    })};

    margin: 0 auto;
`;

const ModalNavigation = styled.div`
    text-align: center;
    ${grid.gutter('margin-bottom', {
        0: 2,
        20: 4,
    })};

    span {
        cursor: pointer;
        background: ${colors.white__off};
        color: ${colors.coral};
        font-weight: bold;
        margin: 0 0.4em;
        padding: 0.3em;
        min-width: 50px;
        display: inline-block;
        user-select: none;
    }

    span.fake {
        background: ${colors.white};
        margin: 0 0.4em;
        padding: 0.3em;
        min-width: 50px;
        display: inline-block;
    }
`;

const ModalContent = styled.div`
    ${grid.columns('width', {
        0: 4,
        10: 6,
        40: 4,
    })};

    ${grid.columns('margin-left', {
        0: () => 0,
        10: [1, 0],
        20: [3, 0],
        40: [4, 0],
    })};

    ${grid.columns('margin-bottom', {
        0: 2,
    })};

    padding-bottom: 100px;

    text-align: center;

    h5 {
        margin-bottom: 0;
    }

    p,
    ul {
        text-align: left;
    }

    li {
        margin-bottom: 0.5em;
    }
`;

const CaseCollaboration = styled.div`
    ${fonts.size(14)};
    text-transform: uppercase;
    font-weight: bold;
    a {
        color: ${colors.coral};
        text-decoration: none;
    }
`;

const CaseImageWrapper = styled.div`
    ${grid.columns('height', {
        0: 2.5,
        10: 3,
        20: 2.5,
    })};

    ${grid.gutter('margin-bottom', {
        0: 2,
        10: 2,
        20: 3,
    })};

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 250px;
    > div {
        ${grid.columns('width', {
            0: 2.5,
            10: 3,
            20: 2.5,
        })};
    }
`;

const Awards = styled.div`
    h5 {
        margin-bottom: 1em;
    }
    p {
        text-align: center;
        margin-bottom: 0;
        margin-top: 0.2em;
    }
`;

const modalCss = css`
    .modal {
        position: fixed;
        max-width: none;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .overlay {
        padding: 0;
    }

    .transitionEnter {
        transform: translateY(100vw);
        opacity: 0;
    }

    .transitionEnterActive {
        transform: translateY(0);
        opacity: 1;
        transition: transform 400ms ${easings.smooth.in}, opacity 400ms 100ms;
    }

    .transitionExit {
        transform: translateY(0);
        opacity: 1;
    }

    .transitionExitActive {
        transform: translateY(-100vw);
        opacity: 0;
        transition: transform 800ms ${easings.smooth.out}, opacity 400ms 300ms;
    }

    .case-transition {
        position: absolute;
        will-change: transform, opacity;
    }

    .case-transition-enter {
        opacity: 0.01;
        transform: scale(0.9) translateY(10%);
    }
    .case-transition-enter-active {
        opacity: 1;
        transform: scale(1) translateY(0%);
        transition: all 500ms ${easings.smooth.out};
    }
    .case-transition-exit {
        opacity: 1;
        transform: scale(1) translateY(0%);
    }
    .case-transition-exit-active {
        opacity: 0.01;
        transform: scale(0.7) translateY(20%);
        transition: all 300ms ${easings.smooth.out};
    }
`;

export default CaseModalComponent;
