import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import styled from '@emotion/styled';
import { colors, grid, layout } from 'src/styles';

class ContactComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const d = this.props.data;
        return (
            <>
                <Contact>
                    <Content>
                        <h3>Remotely</h3>
                        <p>
                            <a href={`mailto:${d.email}`}>{d.email}</a>
                            <br />
                            {d.phone}
                            <br />
                            <br />
                            {d.address1}
                            <br />
                            {d.address2}
                            <br />
                            <br />
                            CVR: {d.cvr}
                        </p>
                    </Content>
                </Contact>
            </>
        );
    }
}

const Contact = styled.div`
    ${layout.fullWidthBackground(colors.gray)}

    ${grid.gutter(['padding-top', 'padding-bottom'], {
        0: 1.5,
        20: 2,
    })}

    ${grid.gutter(['margin-bottom'], {
        0: 2,
        20: 4,
    })}

	color: ${colors.blue};
`;

const Content = styled.div`
    ${grid.columns(['width'], {
        0: 4,
        10: 6,
        20: 6,
        30: 5,
    })}

    ${grid.columns(['margin-left'], {
        0: 0,
        10: [1, 0],
        20: [5, 0],
    })}

	a {
        color: ${colors.blue};
    }
`;

export default props => (
    <StaticQuery
        query={graphql`
            query {
                datoCmsContact {
                    email
                    phone
                    address1
                    address2
                    cvr
                }
            }
        `}
        render={data => (
            <ContactComponent
                {...{
                    data: data.datoCmsContact,
                    ...props,
                }}
            />
        )}
    />
);
