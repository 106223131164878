import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import React, { PureComponent } from 'react';
import { colors, grid, layout, responsive } from 'src/styles';
import AuthorImage from './authorImage';

class IntroductionComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Introduction>
                    <AuthorImage />
                    <AuthorIntro>
                        <h2>{this.props.title}</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.content,
                            }}
                        />
                        <p>/Anders</p>
                    </AuthorIntro>
                </Introduction>
            </>
        );
    }
}

const Introduction = styled.div`
    ${layout.fullWidthBackground(colors.coral)}

    ${grid.gutter(['padding-top', 'padding-bottom'], {
        0: 3,
        20: 4,
    })}

    ${responsive.property(['margin-top'], {
        0: 40,
        20: 80,
    })}

    ${responsive.property(['flex-direction'], {
        0: 'column',
        20: 'row',
    })}

    display: flex;
	align-items: center;
    color: ${colors.gray};
`;

const AuthorIntro = styled.div`
    ${grid.columns(['width'], {
        0: 4,
        10: 6,
        30: 5,
    })}

    ${grid.columns(['margin-left'], {
        0: 0,
        20: [1, 2],
    })}


    h2 {
        color: ${colors.blue};
        font-weight: bold;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    > p:last-of-type {
        color: ${colors.blue};
        font-weight: bold;
    }

    li {
        position: relative;
        left: 0.8em;
        margin-bottom: 0.4em;
        &:before {
            content: '-';
            display: block;
            position: absolute;
            right: calc(100% + 0.2em);
        }
    }
`;

export default props => (
    <StaticQuery
        query={graphql`
            query {
                datoCmsIntroduction {
                    title
                    content
                }
            }
        `}
        render={data => (
            <IntroductionComponent
                {...{
                    title: data.datoCmsIntroduction.title,
                    content: data.datoCmsIntroduction.content,
                    ...props,
                }}
            />
        )}
    />
);
