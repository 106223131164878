import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import styled from '@emotion/styled';
import { colors, grid, layout } from 'src/styles';

class JegComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Jeg>
                    <Content>
                        <h1>Jeg...</h1>
                        <ul>
                            {this.props.oneliners.map((oneliner, index) => {
                                return (
                                    <li key={index}>
                                        {oneliner.node.oneliner}
                                    </li>
                                );
                            })}
                        </ul>
                    </Content>
                </Jeg>
            </>
        );
    }
}

const Jeg = styled.div`
    ${layout.fullWidthBackground(colors.gray)};

    ${grid.gutter(['padding-top', 'padding-bottom'], {
        0: 1.5,
        20: 4,
    })};

    ${grid.gutter(['margin-bottom'], {
        0: 0,
        20: 0,
    })};
`;

const Content = styled.div`
    ${grid.columns(['width'], {
        0: 4,
        10: 6,
        20: 8,
    })};

    ${grid.columns(['margin-left'], {
        0: 0,
        10: [1, 0],
    })};

    color: ${colors.blue};

    h1 {
        margin-bottom: 1em;
        color: ${colors.coral};
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        position: relative;
        margin-left: 35px;
        margin-bottom: 1em;

        &::before {
            position: absolute;
            right: calc(100% + 5px);
            content: '...';
        }
    }

    a {
        color: ${colors.blue};
    }
`;

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allDatoCmsJeg(sort: { fields: [position], order: ASC }) {
                    edges {
                        node {
                            oneliner
                        }
                    }
                }
            }
        `}
        render={data => (
            <JegComponent
                {...{
                    oneliners: data.allDatoCmsJeg.edges,
                    ...props,
                }}
            />
        )}
    />
);
