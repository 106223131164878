import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { grid } from 'src/styles';

const AuthorImageComponent = () => (
    <StaticQuery
        query={graphql`
            query {
                datoCmsIntroduction {
                    author {
                        fluid(maxWidth: 400, maxHeight: 400) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                }
            }
        `}
        render={data => (
            <AuthorImage>
                <Img
                    fadeIn={true}
                    fluid={data.datoCmsIntroduction.author.fluid}
                />
            </AuthorImage>
        )}
    />
);

const AuthorImage = styled.div`
    ${grid.columns(['width', 'height'], {
        0: 2,
        10: 3,
    })}

    ${grid.columns(['margin-left'], {
        0: [0, 0],
        20: [1, 0],
    })}

    ${grid.gutter(['margin-bottom'], {
        0: 2,
        20: 0,
    })}

    display: block;
    flex-shrink: 0;
    overflow: hidden;

    img {
        ${grid.columns(['width', 'height'], {
            0: 2,
            10: 3,
        })}

        display: block;
        border-radius: 100%;
    }
`;

export default AuthorImageComponent;
