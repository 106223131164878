import React, { Component } from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styled from '@emotion/styled';
import { grid } from 'src/styles';

import CaseModal from './caseModal';

class CasesComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalCaseIndex: null,
        };

        this.previousCase = this.previousCase.bind(this);
        this.nextCase = this.nextCase.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    previousCase() {
        const caseIndex = this.state.modalCaseIndex - 1;
        this.setState(() => ({
            modalCaseIndex: caseIndex,
        }));
    }

    nextCase() {
        const caseIndex = this.state.modalCaseIndex + 1;
        this.setState(() => ({
            modalCaseIndex: caseIndex,
        }));
    }

    openModal(caseIndex) {
        this.setState(() => ({
            modalOpen: true,
            modalCaseIndex: caseIndex,
        }));
    }

    closeModal() {
        this.setState(() => ({
            modalOpen: false,
        }));
    }

    render() {
        return (
            <>
                <Cases>
                    <h1>Udvalgte projekter</h1>
                    <div>
                        {this.props.cases.map((project, index) => {
                            return (
                                <Case key={project.node.title}>
                                    <CaseImageWrapper>
                                        <div
                                            onClick={() =>
                                                this.openModal(index)
                                            }>
                                            <Img
                                                title={`${
                                                    project.node.client
                                                } - ${project.node.title}`}
                                                alt={`${
                                                    project.node.client
                                                } logo`}
                                                fadeIn={true}
                                                fluid={
                                                    project.node.clientLogo
                                                        .fluid
                                                }
                                            />
                                        </div>
                                    </CaseImageWrapper>
                                </Case>
                            );
                        })}
                    </div>
                    <CaseModal
                        open={this.state.modalOpen}
                        closeModal={this.closeModal}
                        showPrevious={this.state.modalCaseIndex > 0}
                        showNext={
                            this.state.modalCaseIndex <
                            this.props.cases.length - 1
                        }
                        previousCase={this.previousCase}
                        nextCase={this.nextCase}
                        caseIndex={this.state.modalCaseIndex}
                        cases={this.props.cases}
                    />
                </Cases>
            </>
        );
    }
}

const Cases = styled.div`
    ${grid.gutter(['margin-bottom'], {
        0: 2,
        20: 4,
    })}

    h1 {
        ${grid.columns(['margin-left'], {
            0: () => 0,
            20: [1, 0],
        })}
        margin-bottom: 2em;
    }

    > div {
        display: flex;
        flex-wrap: wrap;
    }
`;

const Case = styled.div`
    ${grid.columns('flex-basis', {
        0: [2, -2],
        10: [4, -2],
        20: [4, -2],
    })};

    &:nth-of-type(2n) {
        ${grid.gutter('margin-left', {
            0: () => 0,
            10: 2,
            20: () => 0,
        })};
    }

    &:nth-of-type(3n + 2),
    &:nth-of-type(3n + 3) {
        ${grid.gutter('margin-left', {
            0: () => 0,
            20: 2,
        })};
    }
`;

const CaseImageWrapper = styled.div`
    ${grid.columns('height', {
        0: 2,
        10: 3.5,
        20: [6, -2],
    })};

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 250px;
    > div {
        cursor: pointer;
        ${grid.columns('width', {
            0: 1.5,
            10: 3,
            20: 2.5,
        })};

        ${grid.columns('max-width', {
            0: 2,
            10: () => '200px',
            20: () => '200px',
            30: 2,
        })};
    }
`;

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allDatoCmsProject(sort: { fields: [position], order: ASC }) {
                    edges {
                        node {
                            position
                            title
                            client
                            clientLogo {
                                fluid {
                                    ...GatsbyDatoCmsFluid_noBase64
                                }
                            }
                            collaboration
                            collaborationLink
                            description
                            link
                            awards {
                                name
                                link
                            }
                        }
                    }
                }
            }
        `}
        render={data => (
            <CasesComponent
                {...{ cases: data.allDatoCmsProject.edges, ...props }}
            />
        )}
    />
);
