import React from 'react';
import Contact from 'src/components/contact';
import Introduction from 'src/components/introduction';
import Layout from 'src/components/layout';
import Cases from 'src/components/cases';
import Jeg from 'src/components/jeg';
import SEO from 'src/components/seo';

const IndexPage = () => (
    <Layout>
        <SEO />
        <Introduction />
        <Contact />
        <Cases />
        <Jeg />
    </Layout>
);

export default IndexPage;
